var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"input",class:{
    'input--filled': !!_vm.value,
    'input--error': !!_vm.error,
    'input--icon-left': _vm.iconPosition === 'left',
    'input--icon-right': _vm.iconPosition === 'right',
    'input--disabled': _vm.disabled,
    'input--modified': _vm.modified,
  }},[(_vm.title)?_c('span',{staticClass:"input__title"},[_c('span',[_vm._v(_vm._s(_vm.title))]),(_vm.required)?_c('span',{staticClass:"input__required"},[_vm._v("*")]):_vm._e()]):_vm._e(),_c('span',{staticClass:"input__container"},[(_vm.iconPosition)?_c('button',{staticClass:"input__action",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('action')}}},[_vm._t("icon",function(){return [_c('IconComponent',{attrs:{"name":"search"}})]})],2):_vm._e(),_c('input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],ref:"input",staticClass:"input__field",attrs:{"type":_vm.type,"placeholder":_vm.placeholder,"min":_vm.min,"max":_vm.max,"disabled":_vm.disabled,"autocomplete":_vm.autocomplete},domProps:{"value":_vm.value},on:{"keydown":_vm.handleKeyDown,"input":_vm.handleInput,"focus":_vm.focus,"blur":function($event){return _vm.$emit('blur', $event)}}})]),(_vm.subtitle)?_c('span',{staticClass:"input__subtitle"},[_vm._v(_vm._s(_vm.subtitle))]):_vm._e(),(_vm.error)?_c('span',{staticClass:"input__error"},[_vm._v(_vm._s(_vm.error))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }