<template>
  <div class="banners">
    <SliderComponent
      class="banners__slider"
      :items="banners"
      v-slot="{ item }"
      :slider-options="sliderOptions"
    >
      <div class="banners__slider-img">
        <a :href="item.url" class="ratio-container">
          <ImgComponent :img="item.img" :lazy="false" />
        </a>
      </div>
    </SliderComponent>
    <!--    <DoctorReferralComponent />-->
  </div>
</template>

<script>
import SliderComponent from "components/SliderComponent.vue";
import DoctorReferralComponent from "components/DoctorReferralComponent.vue";

export default {
  name: "BannersComponent",
  props: {
    banners: Array,
  },
  data() {
    return {
      sliderOptions: {
        slidesPerView: 1,
        pagination: false,
        clickable: false,
        preloadImages: true,
        loop: true,
        autoplay: {
          delay: 5000,
        },
      },
    };
  },
  components: { DoctorReferralComponent, SliderComponent },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
.banners {
  //display grid
  //grid-template-columns 920fr 440fr
  //grid-gap var(--gap)
  //+below(1100px) {
  //  grid-template-columns 1fr
  //}
  //+below(640px) {
  //  grid-gap 20px
  //}

  &__slider {
    position relative
    border-radius var(--big-radius)
    overflow hidden

    .slider-arrows {
      position absolute
      left 40px
      bottom 40px
			+below(1024px) {
				display: none
			}
      +below(540px) {
        left 15px
        bottom 15px
        gap: 5px
      }

      &__item {
				width: 42px
				height: 42px
				background: var(--blue)

        .icon svg path {
          fill: var(--white)
        }
      }
    }

    &-img {
      ratio(920, 500);
    }
  }
}
</style>
